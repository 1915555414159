<template>
  <div>
    <menu-top/>

    <menu-side :drawer="collapseSidebar"/>

    <v-main>
      <div id="home">
        <div class="gr-video-bg">
          <video  autoplay muted loop id="myVideo">
            <source src="https://joy.videvo.net/videvo_files/video/free/2014-12/large_watermarked/Office_Stapler_Red_with_Paper_preview.mp4" type="video/mp4">
            Your browser does not support HTML5 video.
          </video>
        </div>

        <section>
          <v-container>
            <v-row class="text-center">
              <v-col cols="12">
                <v-img
                        :src="require('../assets/img/cartel-gr-color.svg')"
                        class="my-3"
                        contain
                        height="200"
                />
              </v-col>
            </v-row>
          </v-container>
        </section>

        <Footer/>
      </div>
    </v-main>

    <menu-bottom/>
  </div>

</template>

<script>
  import MenuSide from "@/components/MenuSideCat";
  import MenuTop from "@/components/MenuTopCat";
  import MenuBottom from "@/components/MenuBottomCat";
  import { mapGetters } from 'vuex';

  import Footer from "@/components/FooterCat";
  export default {
    name: 'PageHome',
    data: () => ({
    }),
    computed: {
      ...mapGetters(['collapseSidebar']),
    },
    methods: {
    },

    components: {
      Footer,
      MenuSide,
      MenuTop,
      MenuBottom
    }
  }
</script>
