<template>
  <div>
    <menu-top/>

    <menu-side :drawer="collapseSidebar"/>

    <v-main>
      <div id="aviso-legal">

        <section>
          <v-container>
            <v-row justify="center">
              <v-col cols="10">
                <h1 class="mt-10 mb-10">AVÍS LEGAL</h1>

                <h2>OBJECTE</h2>
                <p>
                  El present avís legal regula l'ús i utilització del lloc web www.gestoriariola.com  del qual és titular GESTORIA RIOLA, S.L..
                  La navegació pel lloc web li atribueix la condició d'USUARI d'aquest i comporta la seva acceptació plena i sense reserves de totes i cadascuna de les condicions publicades en aquest avís legal, advertint que aquestes condicions poden ser modificades sense notificació prèvia per part de GESTORIA RIOLA, S.L. en aquest cas es procedirà a la seva publicació i avís amb la màxima antelació possible.
                  Per això és recomanable llegir amb atenció el contingut en cas de desitjar accedir i fer ús de la informació i dels serveis oferts des d'aquest lloc web.
                  L'usuari, a més, s'obliga a fer un ús correcte del lloc web de conformitat amb les lleis, la bona fe, l'ordre públic, els usos del tràfic i el present Avís Legal, i respondrà davant GESTORIA RIOLA, S.L. o davant de tercers, de qualssevol danys i perjudicis que poguessin causar com a conseqüència de l'incompliment d'aquesta obligació.
                  Qualsevol utilització diferent de l'autoritzada està expressament prohibida, i pot GESTORIA RIOLA, S.L. denegar o retirar l'accés i el seu ús en qualsevol moment.
                </p>

                <h3>1. IDENTIFICACIÓ</h3>
                <p>GESTORIA RIOLA, S.L. en compliment de la Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i de Comerç Electrònic, l'informa que:</p>
                <ul>
                  <li>La seva denominació social és: GESTORIA RIOLA, S.L.</li>
                  <li>El seu nom comercial és: GESTORIA RIOLA</li>
                  <li>El seu CIF és: B43785096</li>
                  <li>El seu domicili social està en: RAMBLA NOVA Nº 110 2º 2ª, 43001 TARRAGONA</li>
                  <li>Està inscrita en el Registre Mercantil de TARRAGONA · Tomo: 2033 Folio: 121 · Hoja nº T-30053 – Inscripción 8</li>
                </ul>
                <br>

                <h3>2. COMUNICACIONS</h3>
                <p>Per comunicar-se amb nosaltres, posem a la seva disposició diferents mitjans de contacte que detallem a continuació:</p>
                <ul>
                  <li>Tel: <a href="tel:977241707">977241707</a> / <a href="tel:639086934">639086934</a></li>
                  <li>Correu electrònic: <a href="mailto:riola@gestoriariola.com">riola@gestoriariola.com</a></li>
                  <li>Adreça postal: RAMBLA NOVA Nº 110, 43001 TARRAGONA</li>
                </ul>
                <br>
                <p>Totes les notificacions i comunicacions entre els usuaris i GESTORIA RIOLA es consideraran eficaços, a tots els efectes, quan es realitzin a través de qualsevol mitjà dels detallats anteriorment.</p>

                <h3>3. CONDICIONS D'ACCÉS I UTILITZACIÓ</h3>
                <p>
                  El lloc web i els seus informacions són d'accés lliure i gratuït. No obstant això, GESTORIA RIOLA pot condicionar la utilització d'alguns dels serveis oferts a la seva web a la prèvia formalització del corresponent contracte de prestació de serveis sota llicències.
                  L'usuari garanteix l'autenticitat i actualitat de totes aquelles dades que comuniqui a GESTORIA RIOLA i serà l'únic responsable de les manifestacions falses o inexactes que realitzi.
                  L'usuari es compromet expressament a fer un ús adequat dels continguts i serveis de GESTORIA RIOLA i a no emprar-los per, entre d'altres:
                </p>
                <ol>
                  <li>Difondre continguts delictius, violents, pornogràfics, racistes, xenòfobs, ofensius, d'apologia del terrorisme o, en general, contraris a la llei o a l'ordre públic.</li>
                  <li>Introduir a la xarxa virus informàtics o realitzar actuacions susceptibles d'alterar, espatllar, interrompre o generar errors o danys en els documents electrònics, dades o sistemes físics i lògics de GESTORIA RIOLA, S.L. o de terceres persones; així com obstaculitzar l'accés d'altres usuaris al lloc web i als seus serveis mitjançant el consum massiu dels recursos informàtics a través dels quals GESTORIA RIOLA presta els seus serveis.</li>
                  <li>Intentar accedir als comptes de correu electrònic d'altres usuaris o a àrees restringides dels sistemes informàtics de GESTORIA RIOLA o de tercers i, si s'escau, extreure informació.</li>
                  <li>Vulnerar els drets de propietat intel·lectual o industrial, així com violar la confidencialitat de la informació de GESTORIA RIOLA o de tercers.</li>
                  <li>Suplantar la identitat de qualsevol altre usuari.</li>
                  <li>Reproduir, copiar, distribuir, posar a disposició de, GESTORIA RIOLA o qualsevol altra forma de comunicació pública, transformar o modificar els continguts, llevat que es compti amb l'autorització del titular dels corresponents drets o això resulti legalment permès.</li>
                  <li>Demanar dades amb finalitat publicitària i de remetre publicitat de qualsevol classe i comunicacions amb fins de venda o altres de naturalesa comercial sense que n'hi hagi la prèvia sol·licitud o consentiment.</li>
                </ol>
                <br>

                <p>
                  Tots els continguts del lloc web, com ara textos, fotografies, gràfics, imatges, icones, tecnologia, programari, així com el seu disseny gràfic i codis font, constitueixen una obra la propietat pertany a GESTORIA RIOLA sense que puguin entendre cedits a l'usuari cap dels drets d'explotació sobre els mateixos més enllà de l'estrictament necessari per al correcte ús del web.
                  En definitiva, els usuaris que accedeixin a aquest lloc web poden visualitzar els continguts i efectuar, si escau, còpies privades autoritzades sempre que els elements reproduïts no siguin cedits posteriorment a tercers, ni s'instal·lin a servidors connectats a xarxes, ni siguin objecte de cap tipus d'explotació.
                  Així mateix, totes les marques, noms comercials o signes distintius de qualsevol classe que apareixen al lloc web són propietat de GESTORIA RIOLA, sense que pugui entendre que l'ús o accés al mateix atribueixi a l'usuari cap dret sobre els mateixos.
                  La distribució, modificació, cessió o comunicació pública dels continguts i qualsevol altre acte que no hagi estat expressament autoritzat pel titular dels drets d'explotació queden prohibits.
                  L'establiment d'un hiperenllaç no implica en cap cas l'existència de relacions entre GESTORIA RIOLA i el propietari del lloc web en la qual s'estableixi, ni l'acceptació i aprovació per part de GESTORIA RIOLA dels seus continguts o serveis.
                  GESTORIA RIOLA no es responsabilitza de l'ús que cada usuari els doni als materials posats a disposició en aquest lloc web ni de les actuacions que realitzi en base als mateixos.
                </p>


                <h4>3.1 EXCLUSIÓ DE RESPOSABILIDAD EN L'ACCÉS I LA UTILITZACIÓ DE LA WEB</h4>
                <p>
                  El propietari de la web exclou qualsevol responsabilitat relacionada amb la disponibilitat, fiabilitat i continuïtat del lloc web, encara que sempre intentés restablir com més aviat millor la interrupció i en la mesura de les seves possibilitats disposar de mitjans alternatius.
                  <br>GESTORIA RIOLA exclou, fins on permet l'ordenament jurídic, qualsevol responsabilitat pels danys i perjudicis de tota naturalesa derivats de:
                </p>
                <ol>
                  <li>La impossibilitat d'accés al lloc web o la falta de veracitat, exactitud, exhaustivitat i / o actualitat dels continguts, així com l'existència de vicis i defectes de tota classe dels continguts transmesos, difosos, emmagatzemats, posats a disposició, als quals s'hagi accedit a través del lloc web o dels serveis que s'ofereixen.</li>
                  <li>La presència de virus o d'altres elements en els continguts que puguin produir alteracions en els sistemes informàtics, documents electrònics o dades dels usuaris.</li>
                  <li>L'incompliment de les lleis, la bona fe, l'ordre públic, els usos del tràfic i el present avís legal com a conseqüència de l'ús incorrecte del lloc web. En particular, i a manera d'exemple, GESTORIA RIOLA, S.L. no es fa responsable de les actuacions de tercers que vulnerin drets de propietat intel·lectual i industrial, secrets empresarials, drets a l'honor, a la intimitat personal i familiar i a la pròpia imatge, així com la normativa en matèria de competència deslleial i publicitat il·lícita.</li>
                </ol>
                <br>
                <p>Així mateix, GESTORIA RIOLA declina qualsevol responsabilitat respecte a la informació que es trobi fora d'aquesta web i no sigui gestionada directament pel nostre webmaster. La funció dels links que apareixen en aquesta web és exclusivament la d'informar l'usuari sobre l'existència d'altres fonts susceptibles d'ampliar els continguts que ofereix aquest lloc web. www.gestoriariola.com no garanteix ni es responsabilitza del funcionament o accessibilitat dels llocs enllaçats; ni suggereix, convida o recomana la visita als mateixos, de manera que tampoc serà responsable del resultat obtingut. GESTORIA RIOLA, S.L. no es responsabilitza de l'establiment d’hipervíncles per part de tercers.</p>

                <h4>3.2 PROCEDIMENT EN CAS DE REALITZACIÓ D'ACTIVITATS DE CARÀCTER IL·LÍCIT</h4>
                <p>
                  En el cas que qualsevol usuari o un tercer consideri que existeixen fets o circumstàncies que revelin el caràcter il·lícit de la utilització de qualsevol contingut i / o de la realització de qualsevol activitat en les pàgines web incloses o accessibles a través del lloc web, haurà enviar una notificació a GESTORIA RIOLA identificant-se degudament i especificant les suposades infraccions.
                </p>

                <h4>3.3 PUBLICACIONS</h4>
                <p>
                  La informació administrativa facilitada a través del lloc web no substitueix la publicitat legal de les lleis, normatives, plans, disposicions generals i actes que hagin de ser publicats formalment als diaris oficials de les administracions públiques, que constitueixen l'únic instrument que dona fe de la seva autenticitat i contingut. La informació disponible en aquest lloc web s'ha d'entendre com una guia sense propòsit de validesa legal.            </p>

                <h3>4. POLÍTICA DE PRIVACITAT</h3>
                <h4>4.1 Informació bàsica sobre Protecció de Dades</h4>
                <p>
                  Responsable: GESTORIA RIOLA, S.L.
                  Finalitat: Prestar els serveis sol·licitats, mantenir comunicacions per a la consecució d'aquests i mantenir-lo informat sobre la nostra activitat en general.
                  Legitimació: Execució d'un contracte. Consentiment de l'interessat.
                  Destinataris: No es cediran dades a tercers, excepte obligació legal.
                  Drets: Té dret a accedir, rectificar i suprimir les dades, així com altres drets indicats en la informació addicional, que pot exercir dirigint-se a la direcció <a href="mailto:riola@gestoriariola.com">riola@gestoriariola.com</a>.
                </p>

                <h4>4.2. Informació addicional sobre protecció de dades</h4>
                <br>
                <p>
                  <b>Qui és el responsable del tractament de les seves dades?</b><br>
                  Identitat: GESTORIA RIOLA, S.L. - NIF: B43785096 - Adreça postal: RAMBLA NOVA Nº 110 2º 2ª, 43001 TARRAGONA  Telèfon: 977241707 - Correu electrònic: <a href="mailto:riola@gestoriariola.com">riola@gestoriariola.com</a>
                </p>

                <p>
                  <b>Amb quina finalitat tractem les seves dades personals?</b><br>
                  En GESTORIA RIOLA, S.L. tractem la informació que ens faciliten les persones interessades per tal de prestar-li els serveis concertats, realitzar la gestió administrativa, comptable i fiscal dels serveis sol·licitats, mantenir comunicacions a prop dels mateixos, així com l'enviament de comunicacions inclusivament per correu electrònic, per mantenir-lo informat sobre la nostra activitat en general.
                </p>

                <p>
                  <b>Per quant de temps conservarem les seves dades?</b><br>
                  Les dades es conservaran mentre no sol·liciti la seva supressió, i en qualsevol cas, durant els anys necessaris per complir amb les obligacions legals.
                </p>

                <p>
                  <b>Quina és la legitimació per al tractament de les seves dades?</b><br>
                  Li vam indicar la base legal per al tractament de les seves dades:
                </p>
                <ul>
                  <li>Execució d'un contracte: Prestació dels serveis sol·licitats</li>
                  <li>Consentiment de l'interessat: Enviament de comunicacions comercials sobre la nostra activitat en general.</li>
                </ul>
                <br>

                <p>
                  <b>Transferències de dades a tercers països?</b><br>
                  No se cediran dades a tercers, excepte obligació legal.
                </p>

                <p>
                  <b>Quins són els seus drets quan ens facilita les seves dades?</b><br>
                  No estan previstes transferències de dades a tercers països.
                </p>

                <p>
                  <b>¿Cuáles son sus derechos cuando nos facilita sus datos?</b><br>
                  Qualsevol persona té dret a obtenir confirmació sobre si en GESTORIA RIOLA, S.L. estem tractant dades personals que els concerneixin, o no.
                  Les persones interessades tenen dret a accedir a les seves dades personals, així com a sol·licitar la rectificació de les dades inexactes o, si escau, sol·licitar la seva supressió quan, entre d'altres motius, les dades ja no siguin necessaris per als fins que van ser recollides.
                  En determinades circumstàncies, els interessats podran sol·licitar la limitació del tractament de les seves dades, en aquest cas únicament els conservarem per a l'exercici o la defensa de reclamacions. Així mateix, en els supòsits legalment establerts, tindrà dret a la portabilitat de les seves dades personals.
                  En determinades circumstàncies i per motius relacionats amb la seva situació particular, els interessats podran oposar-se al tractament de les seves dades. En aquest cas GESTORIA RIOLA, S.L. deixarà de tractar les dades, excepte per motius legítims imperiosos, o l'exercici o la defensa de possibles reclamacions.
                  Vostè podrà exercir materialment els seus drets de la següent manera:
                  enviant un correu electrònic a <a href="mailto:riola@gestoriariola.com">riola@gestoriariola.com</a> o una carta a RAMBLA NOVA Nº 110 2º 2ª, 43001 TARRAGONA
                  Si ha atorgat el seu consentiment per a alguna finalitat concreta, té dret a retirar el consentiment atorgat en qualsevol moment, sense que això afecti la licitud del tractament basat en el consentiment previ a la seva retirada.
                  En cas que senti vulnerats els seus drets pel que fa a la protecció de les seves dades personals, especialment quan no hagi obtingut satisfacció en l'exercici dels seus drets, pot presentar una reclamació davant l'Autoritat de Control en matèria de protecció de dades competent a través del seu lloc web: <a href="www.agpd.es" target="_blank">www.agpd.es</a>.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <Footer/>
      </div>
    </v-main>

    <menu-bottom/>
  </div>

</template>

<script>
  import MenuSide from "@/components/MenuSideCat";
  import MenuTop from "@/components/MenuTopCat";
  import MenuBottom from "@/components/MenuBottomCat";
  import { mapGetters } from 'vuex';

  import Footer from "@/components/FooterCat";
  export default {
    name: 'PageHome',
    data: () => ({
    }),
    computed: {
      ...mapGetters(['collapseSidebar']),
    },
    methods: {
    },

    components: {
      Footer,
      MenuSide,
      MenuTop,
      MenuBottom
    }
  }
</script>