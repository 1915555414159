<template>
    <v-app-bar
            app
            class="gr-menu-top"
            dark
    >
        <v-app-bar-nav-icon @click="changeSidebar">
            <v-icon>mdi-dots-grid</v-icon>
        </v-app-bar-nav-icon>

        <img class="gr-logo" src="@/assets/img/logo-ga-white.svg"/>

        <v-toolbar-title>GESTORIA RIOLA</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn text @click="phoneLink" class="hidden-sm-and-down">
            <v-icon>mdi-phone</v-icon>
            <span class="mr-2">Telèfon</span>
        </v-btn>

        <v-btn text @click="whatsappLink" class="hidden-sm-and-down">
            <v-icon>mdi-whatsapp</v-icon>
            <span class="mr-2">Whatsapp</span>
        </v-btn>

        <v-btn text @click="mailLink" class="hidden-sm-and-down">
            <v-icon>mdi-email-outline</v-icon>
            <span class="mr-2">Correu</span>
        </v-btn>

        <v-btn text @click="mapsLink" class="hidden-sm-and-down">
            <v-icon>mdi-google-maps</v-icon>
            <span class="mr-2">On estem</span>
        </v-btn>

        <v-btn text to="/">
            <v-icon>mdi-web</v-icon>
            <span class="mr-2">ESP / <b>CAT</b></span>
        </v-btn>

    </v-app-bar>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "MenuTop",
        computed: {
            ...mapGetters(['collapseSidebar']),
        },
        methods: {
            changeSidebar(value) {
                this.$store.commit('collapseSidebarHandler', value)
            },
            phoneLink() {
                window.open("tel:+34639086934");
            },
            mailLink() {
                window.open("mailto:riola@gestoriariola.com");
            },
            whatsappLink() {
                window.open("//api.whatsapp.com/send?phone=+34639086934&text=Quiero mas informacion!");
            },
            mapsLink() {
                window.open("https://goo.gl/maps/oB7HnhuBQtyNxuJt9");
            }
        },
    }
</script>

<style scoped>

</style>