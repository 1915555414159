<template>
    <v-navigation-drawer
            v-model="changeSidebar"
            class="gr-menu-sidebar"
            absolute
            left
            temporary
    >
        <v-list
                nav
                dense
        >
            <v-list-item-group
                    v-model="group"
                    active-class="deep-purple--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-title>Gestoria Riola</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Sobre nosotros</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Tramites</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Contacto</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "MenuSide",

        data: () => ({
            group: null,
        }),

        computed: {
            ...mapGetters(['collapseSidebar']),
            changeSidebar: {
                get() {
                    return this.$store.state.settings.collapseSidebar
                },
                set(value) {
                    this.$store.commit('collapseSidebarHandler', value)
                }
            }
        },

        watch: {
            group () {
                this.$store.commit('collapseSidebarHandler', false)
            },
        },
    }
</script>

<style scoped>

</style>