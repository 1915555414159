<template>
  <div>
    <menu-top/>

    <menu-side :drawer="collapseSidebar"/>

    <v-main>
      <div id="aviso-legal">

        <section>
          <v-container>
            <v-row justify="center">
              <v-col cols="10">
                <h1 class="mt-10 mb-10">AVISO LEGAL</h1>

                <h2>OBJETO</h2>

                <p>
                  El presente aviso legal regula el uso y utilización del sitio web www.gestoriariola.com  del que es titular GESTORIA RIOLA, S.L.
                  La navegación por el sitio web le atribuye la condición de USUARIO de este y conlleva su aceptación plena y sin reservas de todas y cada una de las condiciones publicadas en este aviso legal, advirtiendo de que dichas condiciones podrán ser modificadas sin notificación previa por parte de GESTORIA RIOLA, S.L., en cuyo caso se procederá a su publicación y aviso con la máxima antelación posible.
                  Por ello es recomendable leer atentamente su contenido en caso de desear acceder y hacer uso de la información y de los servicios ofrecidos desde este sitio web.
                  El usuario, además, se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal, y responderá frente a GESTORIA RIOLA, S.L. o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.
                  Cualquier utilización distinta a la autorizada está expresamente prohibida, pudiendo GESTORIA RIOLA, S.L. denegar o retirar el acceso y su uso en cualquier momento.
                </p>

                <h3>1. IDENTIFICACIÓN</h3>
                <p>GESTORIA RIOLA, S.L., en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, le informa de que:</p>
                <ul>
                  <li>Su denominación social es: GESTORIA RIOLA, S.L.</li>
                  <li>Su nombre comercial es: GESTORIA RIOLA</li>
                  <li>Su CIF es: B43785096</li>
                  <li>Su domicilio social está en: RAMBLA NOVA Nº 110 2º 2ª, 43001 TARRAGONA</li>
                  <li>Está inscrita en el Registro Mercantil de TARRAGONA · Tomo: 2033 Folio: 121 · Hoja nº T-30053 – Inscripción 8</li>
                </ul>
                <br>

                <h3>2. COMUNICACIONES</h3>
                <p>Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a continuación:</p>
                <ul>
                  <li>Tfno.: <a href="tel:977241707">977241707</a> / <a href="tel:639086934">639086934</a></li>
                  <li>Email: <a href="mailto:riola@gestoriariola.com">riola@gestoriariola.com</a></li>
                  <li>Dirección postal: RAMBLA NOVA Nº 110, 43001 TARRAGONA</li>
                </ul>
                <br>
                <p>Todas las notificaciones y comunicaciones entre los usuarios y GESTORIA RIOLA, S.L. se considerarán eficaces, a todos los efectos, cuando se realicen a través de cualquier medio de los detallados anteriormente.</p>

                <h3>3. CONDICIONES DE ACCESO Y UTILIZACIÓN</h3>
                <p>
                  El sitio web y sus informaciones son de acceso libre y gratuito. No obstante, GESTORIA RIOLA, S.L. puede condicionar la utilización de algunos de los servicios ofrecidos en su web a la previa formalización del correspondiente contrato de prestación de servicios bajo licencias.
                  El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a GESTORIA RIOLA, S.L. y será el único responsable de las manifestaciones falsas o inexactas que realice.
                  El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de GESTORIA RIOLA, S.L. y a no emplearlos para, entre otros:
                </p>
                <ol>
                  <li>Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología del terrorismo o, en general, contrarios a la ley o al orden público.</li>
                  <li>Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de GESTORIA RIOLA, S.L. o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales GESTORIA RIOLA, S.L. presta sus servicios.</li>
                  <li>Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de GESTORIA RIOLA, S.L. o de terceros y, en su caso, extraer información.</li>
                  <li>Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de GESTORIA RIOLA, S.L. o de terceros.</li>
                  <li>Suplantar la identidad de cualquier otro usuario.</li>
                  <li>Reproducir, copiar, distribuir, poner a disposición de, GESTORIA RIOLA, S.L. o cualquier otra forma de comunicación pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.</li>
                  <li>Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento.</li>
                </ol>
                <br>

                <p>
                  Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos, tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a GESTORIA RIOLA, S.L. sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web.
                  En definitiva, los usuarios que accedan a este sitio web pueden visualizar los contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen a servidores conectados a redes, ni sean objeto de ningún tipo de explotación.
                  Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de GESTORIA RIOLA, S.L., sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.
                  La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan prohibidos.
                  El establecimiento de un hiperenlace no implica en ningún caso la existencia de relaciones entre GESTORIA RIOLA, S.L. y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por parte de GESTORIA RIOLA, S.L. de sus contenidos o servicios.
                  GESTORIA RIOLA, S.L. no se responsabiliza del uso que cada usuario les dé a los materiales puestos a disposición en este sitio web ni de las actuaciones que realice en base a los mismos.
                </p>


                <h4>3.1 EXCLUSIÓN DE RESPOSABILIDAD EN EL ACCESO Y LA UTILIZACIÓN DE LA WEB</h4>
                <p>El propietario de la web excluye cualquier responsabilidad relacionada con la disponibilidad, fiabilidad y continuidad del sitio web, aunque siempre intentara restablecer a la mayor brevedad posible la interrupción y en la medida de sus posibilidades disponer de medios alternativos.
                  GESTORIA RIOLA, S.L. excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:</p>
                <ol>
                  <li>La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición, a los que se haya accedido a través del sitio web o de los servicios que se ofrecen.</li>
                  <li>La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios.</li>
                  <li>El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto del sitio web. En particular, y a modo ejemplificativo, GESTORIA RIOLA, S.L. no se hace responsable de las actuaciones de terceros que vulneren derechos de propiedad intelectual e industrial, secretos empresariales, derechos al honor, a la intimidad personal y familiar y a la propia imagen, así como la normativa en materia de competencia desleal y publicidad ilícita.</li>
                </ol>
                <br>
                <p>Asimismo, GESTORIA RIOLA, S.L. declina cualquier responsabilidad respecto a la información que se halle fuera de esta web y no sea gestionada directamente por nuestro webmaster. La función de los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este sitio web. GESTORIA RIOLA, S.L. no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido. GESTORIA RIOLA, S.L. no se responsabiliza del establecimiento de hipervínculos por parte de terceros.</p>

                <h4>3.2 PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO</h4>
                <p>
                  En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá enviar una notificación a GESTORIA RIOLA, S.L. identificándose debidamente y especificando las supuestas infracciones.
                </p>

                <h4>3.3 PUBLICACIONES</h4>
                <p>
                  La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio web debe entenderse como una guía sin propósito de validez legal.
                </p>

                <h3>4.	POLÍTICA DE PRIVACIDAD</h3>
                <h4>4.1 Información básica sobre Protección de Datos</h4>
                <p>
                  Responsable: GESTORIA RIOLA, S.L.
                  Finalidad: Prestar los servicios solicitados, mantener comunicaciones para la consecución de estos y mantenerle informado sobre nuestra actividad en general.
                  Legitimación: Ejecución de un contrato. Consentimiento del interesado.
                  Destinatarios: No se cederán datos a terceros, salvo obligación legal.
                  Derechos: Tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos indicados en la información adicional, que puede ejercer dirigiéndose a la dirección  <a href="mailto:riola@gestoriariola.com">riola@gestoriariola.com</a>.
                </p>

                <h4>4.2. Información adicional sobre protección de datos</h4>
                <br>
                <p>
                  <b>¿Quién es el responsable del tratamiento de sus datos?</b><br>
                  Identidad: GESTORIA RIOLA, S.L. – NIF: B43785096 - Dirección postal: RAMBLA NOVA Nº 110 2º 2ª, 43001 TARRAGONA Teléfono: 977241707 - Correo electrónico: <a href="mailto:riola@gestoriariola.com">riola@gestoriariola.com</a>
                </p>

                <p>
                  <b>¿Con qué finalidad tratamos sus datos personales?</b><br>
                  En GESTORIA RIOLA, S.L. tratamos la información que nos facilitan las personas interesadas con el fin de prestarle los servicios concertados, realizar la gestión administrativa, contable y fiscal de los servicios solicitados, mantener comunicaciones a cerca de los mismos, así como el envío de comunicaciones inclusive por correo electrónico, para mantenerle informado sobre nuestra actividad en general.
                </p>

                <p>
                  <b>¿Por cuánto tiempo conservaremos sus datos?</b><br>
                  Los datos se conservarán mientras no solicite su supresión, y en cualquier caso, durante los años necesarios para cumplir con las obligaciones legales.
                </p>

                <p>
                  <b>¿Cuál es la legitimación para el tratamiento de sus datos?</b><br>
                  Le indicamos la base legal para el tratamiento de sus datos:
                </p>
                <ul>
                  <li>Ejecución de un contrato: Prestación de los servicios solicitados</li>
                  <li>Consentimiento del interesado: Envío de comunicaciones comerciales sobre nuestra actividad en general.</li>
                </ul>
                <br>

                <p>
                  <b>¿A qué destinatarios se comunicarán sus datos?</b><br>
                  No se cederán datos a terceros, salvo obligación legal.
                </p>

                <p>
                  <b>¿Transferencias de datos a terceros países?</b><br>
                  No están previstas transferencias de datos a terceros países.
                </p>

                <p>
                  <b>¿Cuáles son sus derechos cuando nos facilita sus datos?</b><br>
                  Cualquier persona tiene derecho a obtener confirmación sobre si en GESTORIA RIOLA, S.L. estamos tratando datos personales que les conciernan, o no.
                  Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.
                  En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones. Asimismo, en los supuestos legalmente establecidos, tendrá derecho a la portabilidad de sus datos personales.
                  En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En este caso GESTORIA RIOLA, S.L. dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
                  Podrá ejercitar materialmente sus derechos de la siguiente forma:
                  enviando un correo electrónico a  <a href="mailto:riola@gestoriariola.com">riola@gestoriariola.com</a>  o una carta a RAMBLA NOVA Nº 110 2º 2ª, 43001 TARRAGONA
                  Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
                  En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: <a href="www.agpd.es" target="_blank">www.agpd.es</a>.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <Footer/>
      </div>
    </v-main>

    <menu-bottom/>
  </div>

</template>

<script>
  import MenuSide from "@/components/MenuSideEsp";
  import MenuTop from "@/components/MenuTopEsp";
  import MenuBottom from "@/components/MenuBottomEsp";
  import { mapGetters } from 'vuex';

  import Footer from "@/components/FooterEsp";
  export default {
    name: 'PageHome',
    data: () => ({
    }),
    computed: {
      ...mapGetters(['collapseSidebar']),
    },
    methods: {
    },

    components: {
      Footer,
      MenuSide,
      MenuTop,
      MenuBottom
    }
  }
</script>