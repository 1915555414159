<template>
    <v-bottom-navigation
            class="gr-menu-bottom hidden-md-and-up"
            color="teal"
            grow
    >
        <v-btn @click="phoneLink">
            <span class="mr-2">Telefono</span>
            <v-icon color="blue">mdi-phone</v-icon>
        </v-btn>

        <v-btn @click="mailLink">
            <span class="mr-2">Correo</span>
            <v-icon color="cyan">mdi-email-outline</v-icon>
        </v-btn>

        <v-btn @click="whatsappLink">
            <span class="mr-2">Whatsapp</span>
            <v-icon color="green">mdi-whatsapp</v-icon>
        </v-btn>

        <v-btn @click="mapsLink">
            <span class="mr-2">Maps</span>
            <v-icon color="red darken-4">mdi-google-maps</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "MenuBottom",
        computed: {
            ...mapGetters(['collapseSidebar']),
        },
        methods: {
            changeSidebar(value) {
                this.$store.commit('collapseSidebarHandler', value)
            },
            phoneLink() {
                window.open("tel:+34639086934");
            },
            mailLink() {
                window.open("mailto:riola@gestoriariola.com");
            },
            whatsappLink() {
                window.open("//api.whatsapp.com/send?phone=+34639086934&text=Quiero mas informacion!");
            },
            mapsLink() {
                window.open("https://goo.gl/maps/oB7HnhuBQtyNxuJt9");
            }
        }
    }
</script>

<style scoped>

</style>